import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,
  isAffiliated: true,

  rivalName: "paradise8",
  casinoId: 13,
  GA_TRACKING: "G-9BPNVMTY3E",

  rivalChatGroupName: "Paradise8",
  prettyName: "Paradise8",
  contactEmail: "support@paradise8.com",
  docsEmail: "documents@paradise8.email",

  publicPromotions: [
    {id: 'PARADISE8_1', name: "deposit", state: "eligible", details: "welcome", imgPath: "assets/brands/paradise8/promo.png"},
    {id: 2, name: "cashbackInsurance", state: "eligible", details: "welcome", imgPath: "assets/brands/paradise8/promo.png"},
    {id: 'PARADISE8_3', name: "nextDayCashback", state: "eligible", details: "weekly", imgPath: "assets/brands/paradise8/promo.png"},
    {id: 'PARADISE8_4', name: "loyalty", state: "eligible", details: "weekly", imgPath: "assets/brands/paradise8/promo.png"}
  ],

  legalData: {
    withdrawalTime: 12,
    minDepositAmount: 5,
    affTerms: true
  },

  //SEO
  metaDescription: "Join Paradise 8 Casino for real cash betting and online casino gaming with top slots, table games, and live dealer gambling. Earn no deposit bonus, free spins and affiliate rewards. Make deposits even in crypto, get fast and secured payouts immediately!",
  logoName: "paradise8-online-casino-logo.png",
  logoAltText: "Paradise 8 Casino logo a bright, energetic green and rich, bold purple color, all woven together with a very stylish script. This is a fun online casino that promises a large variety of slots, a good selection of table games, and some really attractive promotions. Security and excitement at Paradise 8 Casino."

};


